import Vue from 'vue';
import axios from "axios";
import VueAxios from "vue-axios";
import JQuery from 'jquery'

window.$ = JQuery

import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';

import router from './router'


const instance = axios.create({
  timeout: 900000, // 15 minutes
});

Vue.use(VueAxios, instance);
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
