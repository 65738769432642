<template>
  <div>
    <a-row :gutter="24" class="pb-3">
      <a-col :xs="12" :span="21" class="check-box">
        <a-button type="primary" @click="addDataShow"
          >Add Expense</a-button
        ></a-col
      >
      <a-col :xs="8" :lg="3" :span="3" class="float-right"> </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      style="width: 100%"
      :scroll="{ x: 1200 }"
      :loading="isLoading"
    >
      <span slot="notes-Title"
        >Notes
        <a-input @change="filterChange($event.target.value, 'notes-Title')"
      /></span>
      <span slot="location-Title"
        >Location
        <a-input @change="filterChange($event.target.value, 'location-Title')"
      /></span>
      <span slot="expenseDate-Title"
        >Expense Date
        <a-input
          @change="filterChange($event.target.value, 'expenseDate-Title')"
      /></span>
      <span slot="expenseCategory-Title"
        >Expense 
        <a-input
          @change="filterChange($event.target.value, 'expenseCategory-Title')"
      /></span>
      <span slot="department-Title"
        >Department
        <a-input
          @change="filterChange($event.target.value, 'department-Title')"
      /></span>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
    responsive: ["sm"],
  },
  {
    title: "Document Number",
    dataIndex: "documentNumber",
    key: "documentNumber",
    scopedSlots: { customRender: "documentNumber" },
    responsive: ["sm"],
  },
  {
    dataIndex: "expenseDate",
    key: "expenseDate",
    slots: {
      title: "expenseDate-Title",
    },
    responsive: ["sm"],
  },
  {
    dataIndex: "expenseCategory",
    slots: {
      title: "expenseCategory-Title",
    },
    key: "expenseCategory",
    responsive: ["sm"],
  },
  {
    dataIndex: "department",
    slots: {
      title: "department-Title",
    },
    key: "department",
    responsive: ["sm"],
  },
  {
    dataIndex: "location",
    slots: {
      title: "location-Title",
    },
    key: "location",
    responsive: ["sm"],
  },
  {
    key: "notes",
    dataIndex: "notes",
    slots: {
      title: "notes-Title",
    },
    responsive: ["sm"],
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.amount - b.amount,
    responsive: ["sm"],
  },
  {
    title: "GST",
    dataIndex: "gstFlag",
    key: "gstFlag",
    scopedSlots: { customRender: "gstFlag" },
    responsive: ["sm"],
  },
];
export default {
  name: "LandingPage",
  props: {
    loginData: {
      type: Object,
      required: true,
    },
    configData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns,
      data: [],
      isLoading: false,
      orgData: [],
    };
  },
  created() {
    this.data = [];
    let config = {
      headers: { "Content-Type": "text/xml" },
    };
    let urlLogin = "https://api.intacct.com/ia/xml/xmlgw.phtml";
    this.reqBody =
      "<?xml version='1.0' encoding='iso-8859-1'?>\r\n<request>\r\n<control>\r\n  <senderid>" +
      this.configData.senderid +
      "</senderid>\r\n  <password>" +
      this.configData.sender_pass +
      "</password>\r\n  <controlid>123</controlid>\r\n  <uniqueid>false</uniqueid>\r\n  <dtdversion>3.0</dtdversion>\r\n  <includewhitespace>true</includewhitespace>\r\n</control>\r\n<operation transaction='false'>\r\n  <authentication>\r\n   <login>\r\n<userid>" +
      this.loginData.userName +
      "</userid>\r\n        <companyid>" +
      this.loginData.company +
      "</companyid>\r\n        <password>" +
      this.loginData.password +
      "</password>\r\n\r\n   </login>\r\n  </authentication>\r\n<content>\r\n";

    //// department API///
    //Debugger
    let depReqBody =
      this.reqBody +
      "<function controlid='0'>\r\n<query>\r\n<object>PODOCUMENTENTRY</object>\r\n<select>\r\n <field>DOCHDRID</field>\r\n<field>ITEMID</field>\r\n<field>ITEMNAME</field>\r\n<field>ITEMDESC</field>\r\n<field>PRICE</field>\r\n<field>LOCATIONID</field>\r\n<field>LOCATIONNAME</field>\r\n<field>DEPARTMENTID</field>\r\n<field>DEPARTMENTNAME</field>\r\n<field>TAXABSVAL</field>\r\n<field>PODOCUMENT.STATE</field>\r\n<field>PODOCUMENT.PAYMENTSTATUS</field>\r\n<field>PODOCUMENT.PONUMBER</field>\r\n<field>AUWHENCREATED</field>\r\n</select>\r\n<filter>\r\n<and>\r\n<equalto>\r\n<field>PODOCUMENT.CREATEDUSERID</field>\r\n<value>" +
      this.loginData.userName +
      "</value>\r\n</equalto>\r\n<equalto>\r\n<field>PODOCUMENT.DOCPARID</field>\r\n<value>Staff Expense</value>\r\n</equalto>\r\n</and>\r\n</filter>\r\n</query>\r\n</function>\r\n</content>\r\n</operation>\r\n</request>";
    this.isLoading = true;
    this.axios
      .post(urlLogin, depReqBody, config)
      .then((res) => {
        let result = res.data;
        this.data = this.makeArr(result, "PODOCUMENTENTRY");
        this.orgData = JSON.parse(JSON.stringify(this.data));
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.$notification["error"]({
          message: "Something went wrong",
        });
      });
  },
  methods: {
    converDate(start_date) {
      let sd_mm = start_date.substring(0, start_date.indexOf("/"));
      let sd_dd = start_date.substring(
        start_date.indexOf("/") + 1,
        start_date.lastIndexOf("/")
      );
      let sd_yy = start_date.substring(start_date.lastIndexOf("/") + 1);
      start_date = sd_dd + "/" + sd_mm + "/" + sd_yy;
      return start_date;
    },
    filterChange(val, key) {
      let arr = [];
      this.data = JSON.parse(JSON.stringify(this.orgData));
      let currKey = key.substring(0, key.indexOf("-"));
      if (val) {
        for (let i = 0; i < this.data.length; i++) {
          let currArr = this.data[i];
          if (currArr[currKey].indexOf(val) === 0) {
            arr.push(currArr);
          }
        }
        this.data = arr;
      } else {
        this.data = this.orgData;
      }
    },
    makeArr(resData, key) {
      let arr = [];
      let len = $(resData).find(key).length;

      for (let i = 0; i < len; i++) {
        debugger
        let currArr = $(resData).find(key)[i];
        let expenseCategory = $(currArr).find("ITEMNAME").text();
        let gstFlag = $(currArr).find("TAXABSVAL").text();
        let notes = $(currArr).find("ITEMDESC").text();
        let location = $(currArr).find("LOCATIONNAME").text();
        let department = $(currArr).find("DEPARTMENTNAME").text();
        let amount = $(currArr).find("PRICE").text();
        let expenseDate = $(currArr).find("AUWHENCREATED").text();
        expenseDate = this.converDate(
          expenseDate.substring(0, expenseDate.indexOf(" "))
        );
        let status=currArr.getElementsByTagName(
          "PODOCUMENT.STATE"
        )[0].childNodes[0].data;
        if(status=="Closed"){
       status="Approved";
        }
         if(status=="Draft"){
       status="Submitted";
        }
        let documentNumber = currArr.getElementsByTagName(
          "PODOCUMENT.PONUMBER"
        )[0].childNodes[0].data;
        let paystatus=currArr.getElementsByTagName(
          "PODOCUMENT.PAYMENTSTATUS"
        )[0].childNodes[0].data;
        if (paystatus=="Paid"){
          status="Paid";
        }
        // $(currArr).find("PODOCUMENT.PONUMBER").text();
        let form = {
          status:status,
          gstFlag: gstFlag,
          expenseDate: expenseDate,
          expenseCategory: expenseCategory,
          department: department,
          amount: amount,
          notes: notes,
          location: location,
          documentNumber: documentNumber,
        };

        arr.push(form);
      }

      return arr;
    },
    addDataShow() {
      this.$emit("changeFlag", true);
    },
  },
};
</script>
<style scoped>
.pb-3 {
  padding-bottom: 40px;
  margin-bottom: 20px;
}
</style>