<template>
  <div>
    <div style="background: #ececec; padding: 30px; height: 100%" v-if="isHide">
        <a-row :gutter="24" class="pt-3">
          <a-col :xs="24" :lg="24" :span="20">
            <a-card title="Login" class="card-wrapper">
              <a-row :gutter="24" class="pt-3">
                <a-col :span="24">
                  <a-form-item label="Company" :colon="false">
                    <a-input
                      key="company"
                      @change="onChange($event.target.value, 'company')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="User Name" :colon="false">
                    <a-input
                      key="location"
                      @change="onChange($event.target.value, 'userName')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Password" :colon="false">
                    <a-input
                      key="location"
                      type="password"
                      @change="onChange($event.target.value, 'password')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item>
                    <a-button type="primary" @click="loggedIn">
                      Login
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
    </div>
    <home-page
      v-if="isShow"
      :login-data="formData"
      :config-data="configdata"
      :vender-data="venderId"
      :vender-name="venderName"
      :vender-cur="venderCur"
    />
  </div>
    </div>
</template>

<script>
import configdata from "../config";
import HomePage from "./AddTablePage.vue";
export default {
  name: "LoginPage",
  components: {
    HomePage,
  },
  data() {
    return {
      venderId: "",
      venderName:"",
      venderCur:"",
      configdata,
      isShow: false,
      isHide: true,
      formData: {
        userName: "",
        company: "",
        password: "",
      },
    };
  },

  methods: {
    onChange(val, key) {
      this.formData[key] = val;
    },
    loggedIn() {
      let globalVariable = this.configdata;
      let urlLogin = "https://api.intacct.com/ia/xml/xmlgw.phtml";

      if (
        this.formData.userName &&
        this.formData.password &&
        this.formData.company
      ) {
        let config = {
          headers: { "Content-Type": "text/xml" },
        };
        //Debugger
        let reqData =
          "<?xml version='1.0' encoding='iso-8859-1'?>\r\n<request>\r\n<control>\r\n  <senderid>" +
          globalVariable.senderid +
          "</senderid>\r\n  <password>" +
          globalVariable.sender_pass +
          "</password>\r\n  <controlid>123</controlid>\r\n  <uniqueid>false</uniqueid>\r\n  <dtdversion>3.0</dtdversion>\r\n  <includewhitespace>true</includewhitespace>\r\n</control>\r\n<operation transaction='false'>\r\n  <authentication>\r\n   <login>\r\n<userid>" +
          this.formData.userName +
          "</userid>\r\n        <companyid>" +
          this.formData.company +
          "</companyid>\r\n        <password>" +
          this.formData.password +
          "</password>\r\n\r\n   </login>\r\n  </authentication>\r\n<content>\r\n<function controlid='0'>\r\n<readByQuery>\r\n<object>VENDOR</object>\r\n<fields>VENDORID,NAME,CURRENCY</fields>\r\n<query>EXPENSEUSER='" +
          this.formData.userName +
          "'</query>\r\n<pagesize>10</pagesize>\r\n</readByQuery>\r\n</function>\r\n</content>\r\n</operation>\r\n</request>";

        this.axios
          .post(urlLogin, reqData, config)
          .then((res) => {
            this.venderId = "";
            this.venderName="";
            this.venderCur="";
            let result = res.data;
            this.venderId = $(result).find("VENDORID").text();
            
            this.venderName = $(result).find("NAME").text();
             this.venderCur = $(result).find("CURRENCY").text();
            if (this.venderId) {
              this.isShow = true;
              this.isHide = false;
            }else{
             
            alert("Invalid user or password")
          
            }
          })
          .catch((err) => {
            alert("Invalid user or password")
          });
      }
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  width: 50%;
  left: 25%;
}
@media screen and (max-width: 590px) {
  .card-wrapper {
    width: 100%;
    left: 0%;
  }
}
</style>
