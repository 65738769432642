<template>
  <div class="container-wrapper">
    <a-row :gutter="24" class="mt-3">
      <a-col :xs="12" :span="21"> <h1>Staff Expense</h1></a-col>
      <a-col :xs="8" :lg="3" :span="3" class="float-right"> </a-col>
    </a-row>
    <a-row :gutter="24" class="mt-3">
      <a-col :xs="12" :span="21"> <h2>{{venderName}}</h2></a-col>
      <a-col :xs="8" :lg="3" :span="3" class="float-right"> </a-col>
    </a-row>
    <div class="container">
      <div v-if="addDataFlag">
        <a-row :gutter="24" class="pb-3">
          <a-col :xs="12" :span="21" class="check-box">
            <a-button type="primary" @click="viewData"
              >View Expense</a-button
            ></a-col
          >
          <a-col :xs="8" :lg="3" :span="3" class="float-right">
            <a-button type="primary" @click="submitData"> Submit </a-button>
          </a-col>
        </a-row>
        <a-form :form="form" @submit="handleSubmit" v-if="addDataFlag">
          <a-row :gutter="24" class="pt-3">
            <a-col :xs="24" :lg="3" :span="3">
              <a-form-item label="Expense" :colon="false">
                <a-select
                  key="expenseCategory"
                  :value="dropDataShow(form.expenseCategory, 'droData')"
                  @change="onChange(categoryArr[$event], 'expenseCategory')"
                >
                  <a-select-option
                    v-for="(item, index) in categoryArr"
                    :key="index"
                  >
                    {{ item.droData }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :lg="3" :span="3">
              <a-form-item label="Department" :colon="false">
                <a-select
                  key="department"
                  :value="dropDataShow(form.department, 'droData')"
                  @change="onChange(departmentArr[$event], 'department')"
                >
                  <a-select-option
                    v-for="(item, index) in departmentArr"
                    :key="index"
                  >
                    {{ item.droData }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :lg="3" :span="3">
              <a-form-item label="Location" :colon="false">
                <a-select
                  key="location"
                  :value="dropDataShow(form.location, 'droData')"
                  @change="onChange(locationArr[$event], 'location')"
                >
                  <a-select-option
                    v-for="(item, index) in locationArr"
                    :key="index"
                  >
                    {{ item.droData }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :lg="3" :span="3">
              <a-form-item label="Notes" :colon="false">
                <a-input
                  key="notes"
                  :value="form.notes"
                  @change="onChange($event.target.value, 'notes')"
                />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :lg="3" :span="3">
              <a-form-item label="Amount" :colon="false">
                <a-input
                  key="amount"
                  :value="form.amount"
                  @change="onChange($event.target.value, 'amount')"
                />
              </a-form-item>
            </a-col>
            <a-col :xs="8" :lg="3" :span="3">
              <a-form-item label="Expense date" :colon="false">
                <a-date-picker
                  key="expenseDate"
                  format="DD/MM/YYYY"
                  :value="form.expenseDate"
                  @change="onChangeDate"
                />
              </a-form-item>
            </a-col>
            <a-col :xs="8" :lg="3" :span="3">
              <a-form-item label="Upload file" :colon="false">
                <a-upload :file-list="fileList" :before-upload="beforeUpload">
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :xs="8" :lg="3" :span="3" class="pt-5 float-right">
              <a-button type="primary" class="add-btn" @click="addData">
                Add
              </a-button>
            </a-col>
          </a-row>
        </a-form>
        <div v-if="addDataFlag">
          <a-table
            :columns="columns"
            id="custom-table"
            :data-source="data"
            :pagination="false"
             style="width: 100%"
            :scroll="{ x: 1200 }"
            :loading="isLoading"
          >
            <span slot="gstFlag" slot-scope="text, record, index">
              <a-checkbox
                :checked="record.gstFlag"
                @change="onCheck($event.target.checked, 'gstFlag', index)"
              >
                GST
              </a-checkbox>
            </span>
            <span slot="expenseCategory" slot-scope="text, record">
              {{ dropDataShow(record.expenseCategory, "droData") }}
            </span>
            <span slot="department" slot-scope="text, record">
              {{ dropDataShow(record.department, "droData") }}
            </span>
            <span slot="location" slot-scope="text, record">
              {{ dropDataShow(record.location, "droData") }}
            </span>
            <span slot="action" slot-scope="text, record, index">
              <a-tooltip>
                <template #title>Edit</template>
                <a-button
                  icon="edit"
                  size="small"
                  type="link"
                  @click="onEditClick(record, index)"
                />
              </a-tooltip>
              <a-tooltip>
                <template #title>Delete</template>
                <a-button
                  class="mr-2"
                  icon="delete"
                  size="small"
                  type="link"
                  :disabled="record.deleteFlag"
                  @click="onDel(record, index)"
                />
              </a-tooltip>
            </span>
          </a-table>
        </div>
        <a-form>
          <a-modal
            v-model="visible"
            :after-close="afterModalClose"
            destroy-on-close
            title="Edit Item"
            class="model-container"
          >
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Expense date" :colon="false" :span="24">
                  <a-date-picker
                    key="expenseDate"
                    format="DD/MM/YYYY"
                    @change="onChangeDateEdit"
                    :value="moment(form.expenseDate)"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Expense" :colon="false">
                  <a-select
                    key="expenseCategory"
                    :value="dropDataShow(formEdit.expenseCategory, 'droData')"
                    @change="
                      onChangeEdit(categoryArr[$event], 'expenseCategory')
                    "
                  >
                    <a-select-option
                      v-for="(item, index) in categoryArr"
                      :key="index"
                    >
                      {{ item.droData }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Department" :colon="false">
                  <a-select
                    key="department"
                    :value="dropDataShow(formEdit.department, 'droData')"
                    @change="onChangeEdit(departmentArr[$event], 'department')"
                  >
                    <a-select-option
                      v-for="(item, index) in departmentArr"
                      :key="index"
                    >
                      {{ item.droData }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Location" :colon="false">
                  <a-select
                    key="location"
                    :value="dropDataShow(formEdit.location, 'droData')"
                    @change="onChangeEdit(locationArr[$event], 'location')"
                  >
                    <a-select-option
                      v-for="(item, index) in locationArr"
                      :key="index"
                    >
                      {{ item.droData }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Notes" :colon="false" :span="24">
                  <a-input
                    key="notes"
                    :value="formEdit.notes"
                    @change="onChangeEdit($event.target.value, 'notes')"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Amount" :colon="false">
                  <a-input
                    key="amount"
                    :value="formEdit.amount"
                    @change="onChangeEdit($event.target.value, 'amount')"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <a-form-item label="Upload file" :colon="false" :span="24">
                  <a-upload
                    :file-list="fileList"
                    :before-upload="beforeUploadEdit"
                  >
                    <a-button> <a-icon type="upload" /> Select File </a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pt-3">
              <a-col :span="24">
                <span :value="formEdit.viewFile">{{ formEdit.viewFile }}</span>
              </a-col>
            </a-row>

            <template #footer>
              <a-button @click="afterModalClose">Cancel</a-button>
              <a-button type="primary" @click="saveAfterEdit"> Save </a-button>
            </template>
          </a-modal>
        </a-form>
      </div>
      <landing-page
        :login-data="loginData"
        :config-data="configData"
        v-if="viewFlag"
        @changeFlag="changedFlag"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LandingPage from "./LandingPage.vue";
const columns = [
  {
    title: "GST",
    dataIndex: "gstFlag",
    key: "gstFlag",
    scopedSlots: { customRender: "gstFlag" },
    responsive: ["sm"],
    fontSize: "8px",
    
  },
  {
    title: "Expense Date",
    dataIndex: "expenseDateforGrid",
    key: "expenseDate",
    scopedSlots: { customRender: "expenseDate" },
    responsive: ["sm"],
    fontSize: "8px",
   
  },
  {
    title: "Expense",
    dataIndex: "expenseCategory",
    scopedSlots: { customRender: "expenseCategory" },
    key: "expenseCategory",
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Department",
    dataIndex: "department",
    scopedSlots: { customRender: "department" },
    key: "department",
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Location",
    dataIndex: "location",
    scopedSlots: { customRender: "location" },
    key: "location",
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Notes",
    key: "notes",
    dataIndex: "notes",
    scopedSlots: { customRender: "notes" },
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Attached File",
    key: "viewFile",
    dataIndex: "viewFile",
    scopedSlots: { customRender: "viewFile" },
    responsive: ["sm"],
    ellipsis: true,
    width:"100px",
    fontSize: "8px",
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Attachment Status",
    key: "attStatus",
    dataIndex: "attStatus",
    responsive: ["sm"],
    fontSize: "8px",
  },
  {
    title: "Submit Status",
    key: "statusSub",
    dataIndex: "statusSub",
    responsive: ["sm"],
    fontSize: "8px",
  },
];

const data = [];

export default {
  name: "HomePage",
  components: {
    LandingPage,
  },
  props: {
    venderData: {
      type: String,
      required: true,
    },
    venderName: {
      type: String,
      required: true,
    },
    venderCur: {
      type: String,
      required: true,
    },
    loginData: {
      type: Object,
      required: true,
    },
    configData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewFlag: false,
      addDataFlag: true,
      reqBody: "",
      indexofEdit: -1,
      data,
      columns,
      uploading: false,
      visible: false,
      fileList: [],
      formEdit: {},
      locationArr: [],
      departmentArr: [],
      categoryArr: [],
      formEdit: {
        gstFlag: true,
        expenseDate: null,
        expenseCategory: "",
        department: "",
        amount: "",
        notes: "",
        location: "",
        viewFile: "",
        editedFileName: "",
      },
      form: {
        gstFlag: true,
        expenseDate: null,
        expenseCategory: "",
        department: "",
        amount: "",
        notes: "",
        location: "",
        viewFile: "",
        editedFileName: "",
      },
      isLoading: false,
    };
  },
 
  created() {
    (this.departmentArr = []), (this.locationArr = []), (this.categoryArr = []);
    let config = {
      headers: { "Content-Type": "text/xml" },
    };
    let urlLogin = "https://api.intacct.com/ia/xml/xmlgw.phtml";
    this.reqBody =
      "<?xml version='1.0' encoding='iso-8859-1'?>\r\n<request>\r\n<control>\r\n  <senderid>" +
      this.configData.senderid +
      "</senderid>\r\n  <password>" +
      this.configData.sender_pass +
      "</password>\r\n  <controlid>123</controlid>\r\n  <uniqueid>false</uniqueid>\r\n  <dtdversion>3.0</dtdversion>\r\n  <includewhitespace>true</includewhitespace>\r\n</control>\r\n<operation transaction='false'>\r\n  <authentication>\r\n   <login>\r\n<userid>" +
      this.loginData.userName +
      "</userid>\r\n        <companyid>" +
      this.loginData.company +
      "</companyid>\r\n        <password>" +
      this.loginData.password +
      "</password>\r\n\r\n   </login>\r\n  </authentication>\r\n<content>\r\n";

    //// department API///
    let depReqBody =
      this.reqBody +
      "<function controlid='0'>\r\n<readByQuery>\r\n<object>DEPARTMENT</object>\r\n<fields>DEPARTMENTID,TITLE</fields>\r\n<query></query>\r\n<pagesize>100</pagesize>\r\n</readByQuery>\r\n</function>\r\n</content>\r\n</operation>\r\n</request>";

    this.axios
      .post(urlLogin, depReqBody, config)
      .then((res) => {
        let result = res.data;

        this.departmentArr = this.makeArr(
          result,
          "department",
          this.departmentArr,
          "DEPARTMENTID",
          "TITLE"
        );
      })
      .catch((err) => {
        this.$notification["error"]({
          message: "Something went wrong",
        });
      });
    ///department end/////

    ////category api////
    let catReqBody =
      this.reqBody +
      "<function controlid='0'>\r\n<readByQuery>\r\n<object>ITEM</object>\r\n<fields>ITEMID,NAME,TAXABLE</fields>\r\n<query>STAFF_EXPENSE='T'</query>\r\n<pagesize>100</pagesize>\r\n</readByQuery>\r\n</function>\r\n</content>\r\n</operation>\r\n</request>";

    this.axios
      .post(urlLogin, catReqBody, config)
      .then((res) => {
        let result = res.data;

        this.categoryArr = this.makeArr(
          result,
          "ITEM",
          this.categoryArr,
          "ITEMID",
          "NAME",
          "TAXABLE"
        );
      })
      .catch((err) => {
        this.$notification["error"]({
          message: "Something went wrong",
        });
      });
    ///Category end////
//Debugger
    ////location Api////
    let locReqBody =
      this.reqBody +
      "<function controlid='0'>\r\n<readByQuery>\r\n<object>LOCATIONENTITY</object>\r\n<fields>LOCATIONID,NAME,CURRENCY,TAXSOLUTIONID</fields>\r\n<query></query>\r\n<pagesize>100</pagesize>\r\n</readByQuery>\r\n</function>\r\n</content>\r\n</operation>\r\n</request>";

    this.axios
      .post(urlLogin, locReqBody, config)
      .then((res) => {
        let result = res.data;

        this.locationArr = this.makeArr(
          result,
          "LOCATIONENTITY",
          this.locationArr,
          "LOCATIONID",
          "NAME",
          "CURRENCY",
          "TAXSOLUTIONID"
        );
      })
      .catch((err) => {
        this.$notification["error"]({
          message: "Something went wrong",
        });
      });
    ////Location Api end////
  },
  methods: {
    viewData() {
      this.data=[];
      this.viewFlag = true;
      this.addDataFlag = false;
    },
    changedFlag(val) {
      this.viewFlag = !val;
      this.addDataFlag = val;
    },
    makeArr(resData, key, arr, id_key, tit_key,taxable,taxsol) {
      let len = $(resData).find(key).length;
      
      
      for (let i = 0; i < len; i++) {
        let currArr = $(resData).find(key)[i];
        let id = $(currArr).find(id_key).text();
        let title = $(currArr).find(tit_key).text();
        let ztax="";
        if(taxable){
         ztax= $(currArr).find(taxable).text(); 
        }
        let ztaxsol="";
        if(taxsol){
          ztaxsol= $(currArr).find(taxsol).text(); 
        }
        
         arr.push({ id: id, droData: title,thirdfield:ztax,fourthfield:ztaxsol });
      }
      return arr;
    },
    getSubmitRes(resData, key, id_key, tit_key) {
      let len = $(resData).find(key).length;
      for (let i = 0; i < len; i++) {
        let currArr = $(resData).find(key)[i];
        let status = $(currArr).find(id_key).text();
        let control_id = parseInt($(currArr).find(tit_key).text());

        let record = this.data[control_id];
        record.statusSub = status;
        this.data.splice(control_id, 1, record);
      }
    },
    moment,
    dropDataShow(val, key) {
      return val[key];
    },
    onCheck(val, key, index) {
      this.data[index].gstFlag = val;
    },
    onChangeEdit(val, key) {
      this.formEdit[key] = val;
      if(key=="expenseCategory"){
      if (val.thirdfield=="true"){
       this.form.gstFlag=true;
      }else{
       this.form.gstFlag=false; 
      }
      }
    },
    onChangeDateEdit(date, dateString) {
      this.formEdit.expenseDate = moment(dateString, "DD/MM/YYYY");
      this.formEdit.expenseDateforGrid = dateString;
    },
    onChange(val, key) {
      
      this.form[key] = val;
      let timeStamp = new Date();
    let timemonth =timeStamp.getMonth()+1;
      let timeday =  timeStamp.getDate();
      let timeyear= timeStamp.getFullYear();
      let timehour = timeStamp.getHours();
      let timemin = timeStamp.getMinutes()
      let timesec =timeStamp.getSeconds();
  timeStamp = timeyear.toString()+timemonth.toString()+timeday.toString()+timehour.toString()+timesec.toString();
    if(key=="department"){
       val.thirdfield = timeStamp;
    }


      if(key=="expenseCategory"){
      if (val.thirdfield=="true"){
       this.form.gstFlag=true;
      }else{
       this.form.gstFlag=false; 
      }
      }
    },
    onChangeDate(date, dateString) {
      this.form.expenseDate = moment(dateString, "DD/MM/YYYY");
      this.form.expenseDateforGrid = dateString;
    },
    addData() {
      this.isLoading = true;

      if (
        this.form.expenseDate &&
        this.form.expenseCategory &&
        this.form.department &&
        this.form.amount &&
        this.form.location &&
        this.form.notes
      ) {
        this.sendAttachmentApiCall(this.form);

        this.$notification["success"]({
          message: "Data added successfully",
        });
        this.form = {
          expenseDate: null,
          expenseCategory: "",
          department: "",
          fileData: "",
          amount: "",
          notes: "",
          location: "",
          viewFile: "",
          editedFileName: ""
        };
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.$notification["error"]({
          message: "Fill all fields and attached file",
        });
      }
    },
    onEditClick(record, index) {
      this.formEdit = JSON.parse(JSON.stringify(record));
      this.indexofEdit = index;
      this.visible = true;
    },
    afterModalClose() {
      this.visible = false;
    },
    beforeUploadEdit(file) {
      let timeStamp = new Date();
      
      
      let timemonth =timeStamp.getMonth()+1;
      let timeday =  timeStamp.getDate();
      let timeyear= timeStamp.getFullYear();
      let timehour = timeStamp.getHours();
      let timemin = timeStamp.getMinutes()
      let timesec =timeStamp.getSeconds();
     // let timemonth =timemonth.Text();
  timeStamp = timeyear.toString()+timemonth.toString()+timeday.toString()+timehour.toString()+timesec.toString();
     // timeStamp = timeStamp.getDate().text()+timeStamp.getMonth()+1+timeStamp.getFullYear()+timeStamp.getHours()+timeStamp.getMinutes()+timeStamp.getSeconds();
     //timeStamp = timeStamp.getDate().text()+timemonth.Text()+timeStamp.getFullYear().Text()+timeStamp.getHours().Text()+timeStamp.getMinutes().Text()+timeStamp.getSeconds().Text();
      this.getBase64(file, this.formEdit);
      this.formEdit.viewFile = file.name;
      this.formEdit.editedFileName = timeStamp;
      //this.formEdit.fileData=convertedData;
      return false;
    },
    beforeUpload(file) {
       let timeStamp = new Date();
      let timemonth =timeStamp.getMonth()+1;
      let timeday =  timeStamp.getDate();
      let timeyear= timeStamp.getFullYear();
      let timehour = timeStamp.getHours();
      let timemin = timeStamp.getMinutes()
      let timesec =timeStamp.getSeconds();
     // let timemonth =timemonth.Text();
  timeStamp = timeyear.toString()+timemonth.toString()+timeday.toString()+timehour.toString()+timesec.toString();
     // timeStamp = timeStamp.getDate()+timeStamp.getMonth()+1+timeStamp.getFullYear()+timeStamp.getHours()+timeStamp.getMinutes()+timeStamp.getSeconds();
    
    // let timemonth =timeStamp.getMonth()+1;
    //timeStamp = timeStamp.getDate().text()+timemonth.Text()+timeStamp.getFullYear().Text()+timeStamp.getHours().Text()+timeStamp.getMinutes().Text()+timeStamp.getSeconds().Text();
 
     
      this.getBase64(file, this.form);
      this.form.viewFile = file.name;
      this.form.editedFileName = timeStamp;
      return false;
    },
    saveAfterEdit() {
      this.isLoading = true;
      this.formEdit.statusSub = "";
      this.sendAttachmentApiCall(this.formEdit, true);

      this.$notification["success"]({
        message: "Data updated successfully",
      });
      this.visible = false;
      this.isLoading = false;
    },
    onDel(record, index) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete?`,
        content: `If you click Yes, you won’t be able to undo this Delete operation. Are you sure you want delete?`,
        cancelText: "Cancel",
        okText: "Delete",
        okType: "danger",
        onOk: () => {
          this.onConfirmDelete(record, index);
        },
      });
    },
    onConfirmDelete(record, index) {
      this.data.splice(index, 1);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    submitData() {
      this.isLoading = true;
      let resResult;
      let config = {
        headers: { "Content-Type": "text/xml" },
      };
      let urlLogin = "https://api.intacct.com/ia/xml/xmlgw.phtml";
      let currentData = new Date();
       //Debugger
      let DueData = new Date();
      DueData.setDate(DueData.getDate() + 30);
      let reqBodyforSubmitTableData = "";
  
      let currDay,DueDay, currMon,DueMonth, currYear,DueYear;
      
      currDay = currentData.getDate();
      currMon = currentData.getMonth()+1;
      currYear = currentData.getFullYear();
      DueDay = DueData.getDate();
      DueMonth = DueData.getMonth()+1;
      DueYear = DueData.getFullYear();
      if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++){
          if (this.data[i].statusSub!="success"){
          let expCatId = this.data[i].expenseCategory;
          expCatId = expCatId.id;
       
          let expLocId = this.data[i].location;
          let ztaxcur = expLocId.thirdfield;
          let ztaxsolid=expLocId.fourthfield;
          expLocId = expLocId.id;
          let zamt=this.data[i].amount 
          if(this.data[i].gstFlag){
            zamt = (zamt/1.1);
            zamt = zamt.toFixed(2);
          }
   
          let expDepId = this.data[i].department;
          let zdocno = expDepId.thirdfield;
          zdocno = this.loginData.userName+zdocno;
          expDepId = expDepId.id;

          let expDate = this.getDate(this.data[i].expenseDateforGrid);
          let fileNameorg = this.data[i].viewFile;
          let editedFileName = this.data[i].editedFileName;
          let fileName = fileNameorg.substring(0, fileNameorg.indexOf("."));
          
          reqBodyforSubmitTableData +=
            "<function controlid='" +
            i +
            "'>\r\n<create_potransaction>\r\n<transactiontype>Staff Expense</transactiontype>\r\n<datecreated>\r\n<year>" +
            currYear +
            "</year>\r\n<month>" +
            currMon +
            "</month>\r\n<day>" +
            currDay +
            "</day>\r\n</datecreated>\r\n<dateposted>\r\n<year>" +
            expDate.posYear +
            "</year>\r\n<month>" +
            expDate.posMon +
            "</month>\r\n<day>" +
            expDate.posDay +
            "</day>\r\n</dateposted>\r\n<vendorid>" +
            this.venderData +
            "</vendorid>\r\n<documentno>" +
            zdocno +
            "</documentno>\r\n<referenceno>Expense - " +
            this.data[i].expenseDateforGrid +
            "</referenceno>\r\n<vendordocno>"+zdocno+"</vendordocno>\r\n<datedue>\r\n<year>" +
            DueYear +
            "</year>\r\n<month>" +
            DueMonth +
            "</month>\r\n<day>" +
            DueDay +
            "</day>\r\n</datedue>\r\n<message></message>\r\n<returnto>\r\n<contactname></contactname>\r\n</returnto>\r\n<payto>\r\n<contactname></contactname>\r\n</payto>\r\n<supdocid>" +
            // this.loginData.userName +
            // "_" +
            editedFileName +
            "</supdocid>\r\n<basecurr>"+this.venderCur+"</basecurr>\r\n<currency>"+ztaxcur+"</currency>\r\n<exchratetype>Intacct Daily Rate</exchratetype>\r\n<state>Submitted</state>\r\n";
            if(ztaxsolid){
              reqBodyforSubmitTableData += "<taxsolutionid>"+ztaxsolid+"</taxsolutionid>";
            }
           reqBodyforSubmitTableData += "<potransitems>\r\n<potransitem>\r\n<itemid>" +
            expCatId +
            "</itemid>\r\n<itemdesc>" +
            this.data[i].notes +
            "</itemdesc>\r\n<taxable>" +
            this.data[i].gstFlag +
            "</taxable>\r\n<quantity>1</quantity>\r\n<unit>Each</unit>\r\n<price>" +
            zamt +
            "</price>\r\n<locationid>" +
            expLocId +
            "</locationid>\r\n<departmentid>" +
            expDepId +
            "</departmentid>\r\n</potransitem>\r\n</potransitems>\r\n</create_potransaction>\r\n</function>";
        }
        }
 
        let reqBodyData =
          this.reqBody +
          reqBodyforSubmitTableData +
          "\r\n</content>\r\n</operation>\r\n</request>";
        this.axios
          .post(urlLogin, reqBodyData, config)
          .then((res) => {
            let result = res.data;
            this.getSubmitRes(result, "result", "status", "controlid");
            this.isLoading = false;
          })
          .catch((err) => {
            this.$notification["error"]({
              message: "Something went wrong",
            });
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },

    sendAttachmentApiCall(record, editFlag) {
      let i = 0;
      let commonFn = "";
      if (record.viewFile) {
        let fileNameorg = record.viewFile;
        let editedFileName = record.editedFileName;
        let fileName = fileNameorg.substring(0, fileNameorg.indexOf("."));
        let fileType = fileNameorg.substring(fileNameorg.indexOf(".") + 1);
        let convertedFile = record.fileData;
        commonFn +=
          "<function controlid='" +
          i +
          "'>\r\n<create_supdoc>\r\n<supdocid>" +
          // this.loginData.userName +
          // "-" +
          editedFileName +
          "</supdocid>\r\n<supdocfoldername>Expense</supdocfoldername>\r\n<supdocdescription>attachement</supdocdescription>\r\n<attachments>\r\n<attachment>\r\n<attachmentname>" +
          // this.loginData.userName +
          // "-" +
          editedFileName +
          "</attachmentname>\r\n<attachmenttype>" +
          fileType +
          "</attachmenttype>\r\n<attachmentdata>" +
          convertedFile +
          "</attachmentdata>\r\n</attachment>\r\n</attachments>\r\n</create_supdoc>\r\n</function>";

        let reqBodyData =
          this.reqBody +
          commonFn +
          "\r\n</content>\r\n</operation>\r\n</request>";
        let config = {
          headers: { "Content-Type": "text/xml" },
        };
        let urlLogin = "https://api.intacct.com/ia/xml/xmlgw.phtml";
        this.axios
          .post(urlLogin, reqBodyData, config)
          .then((res) => {
            let result = res.data;
            record.attStatus = $(result).find("result status").text();
            if (editFlag) {
              this.data.splice(this.indexofEdit, 1, record);
            } else {
              this.data.push(record);
            }
          })
          .catch((err) => {
            if (editFlag) {
              this.data.splice(this.indexofEdit, 1, record);
            } else {
              this.data.push(record);
            }
            this.$notification["error"]({
              message: "Something went wrong",
            });
          });
      } else {
        if (editFlag) {
          this.data.splice(this.indexofEdit, 1, record);
        } else {
          this.data.push(record);
        }
      }
    },
    getBase64(file, obj) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let finalData = reader.result;
        finalData = finalData.substring(finalData.indexOf(",") + 1);
        obj.fileData = finalData;
      };
      reader.onerror = function (error) {
        obj.fileData = "not converted in base 64";
      };
    },
    getDate(date) {
      let dateObj = {};
      if (date) {
        dateObj.posDay = date.substring(0, date.indexOf("/"));
        dateObj.posMon = date.substring(
          date.indexOf("/") + 1,
          date.lastIndexOf("/")
        );
        dateObj.posYear = date.substring(date.lastIndexOf("/") + 1);
      } else {
        dateObj.posDay = "";
        dateObj.posMon = "";
        dateObj.posYear = "";
      }

      return dateObj;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.add-btn {
  padding-left: 26px;
  padding-right: 26px;
}

.pb-3 {
  padding-bottom: 40px;
  margin-bottom: 20px;
}

.float-right {
  float: right;
  right: 0%;
}
.mt-3 {
  margin-top: 10px;
}
.container-wrapper {
  width: 98%;
  margin: 20px auto;
  padding-top: 15px;
}
.container {
  margin-top: 60px;
}

.container .ant-upload.ant-upload-select {
  display: table !important;
}

.container .ant-form-item-label {
  padding: 8px 0px !important;
}

.ant-form-item {
  margin-top: -25px !important;
}

.ant-form-item-label {
  line-height: 0px !important;
}

.pt-5 {
  padding-top: 16px;
}

@media screen and (max-width: 590px) {
  .float-right {
    text-align: end;
  }

  .pt-5 {
    padding-top: 7px !important;
  }

  .container .ant-form-item-label {
    padding: 8px 0px !important;
  }

  .ant-table-body {
    overflow-x: scroll !important;
  }
}

.model-container .ant-select {
  display: block !important;
}

.container .ant-table-thead > tr > th {
  font-weight: 50 !important;
}
</style>